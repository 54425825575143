import React, { FC, ReactNode, useEffect, useState } from "react";
import { Input } from "./components/Input";
import { Item } from "./components/Item";
import { useCombobox } from "downshift";
import classNames from "classnames";

export interface AutoCompleteItem {
  search: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

interface AutoCompleteProps {
  id?: string;
  isOpen?: boolean;
  placeholder?: string;
  className?: string;
  defaultItems?: AutoCompleteItem[];
  items?: AutoCompleteItem[];
  onMenuChange?: (isOpen: boolean) => void;
  renderItem: (item: AutoCompleteItem) => ReactNode;
  onChange?: (item: AutoCompleteItem) => void;
  onSearch?: (text: string) => void;
  itemToString?: (item: AutoCompleteItem) => string;
  onSubmit?: (value: string) => void;
}

interface AutoCompleteComponents {
  Item: typeof Item;
}

export const AutoComplete: FC<AutoCompleteProps> & AutoCompleteComponents = ({
  id = "autocomplete",
  placeholder,
  isOpen: isMenuOpen = false,
  className = "",
  defaultItems = [],
  renderItem,
  onChange,
  itemToString = undefined,
  onSubmit,
  onMenuChange,
  onSearch,
  items = [],
}) => {
  const [itemsTmp, setItemsTmp] = useState(defaultItems);
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    inputValue,
    closeMenu,
  } = useCombobox({
    id,
    items: itemsTmp,
    initialIsOpen: isMenuOpen,
    itemToString,
    onIsOpenChange: ({ isOpen }) => {
      if (onMenuChange) {
        onMenuChange(!!isOpen);
      }

      if (isOpen) {
        document.getElementById(`${id}-input`)?.focus();
        window.scrollTo(0, 0);
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange && selectedItem && onChange(selectedItem);
      closeMenu();
    },
    onInputValueChange: ({ inputValue }) => {
      setItemsTmp(
        itemsTmp.filter((item) =>
          item.search
            .toLowerCase()
            .startsWith(inputValue ? inputValue.toLowerCase() : "")
        )
      );
      setItemsTmp(itemsTmp);
    },
  });

  // when the items change we updated a itemsTmp state on this component
  useEffect(() => {
    setItemsTmp(items);
  }, [items]);

  const wrapperClass = classNames({
    relative: !isOpen,
    "relative top-0 right-0 left-0 w-full z-20": isOpen,
  });

  return (
    <div className={wrapperClass}>
      <div
        {...getComboboxProps()}
        className={`border-b-2 ${
          isOpen && itemsTmp.length > 0 ? "border-black" : "border-transparent"
        }`}
      >
        <Input
          {...getInputProps({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (e: any) => {
              onSearch && onSearch(e.target.value);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onKeyDown: (e: any) => {
              if (e.key === "Enter") {
                if (
                  isOpen &&
                  itemsTmp.length > 0 &&
                  itemsTmp[0].search.toLowerCase().startsWith(e.target.value)
                ) {
                  selectItem(itemsTmp[0]);
                  return;
                }

                onSubmit && onSubmit(inputValue);
                closeMenu();
              }
            },
          })}
          placeholder={placeholder}
          className={`${className} ${isOpen ? "rounded-b-none" : ""}`}
        />
      </div>
      <ul
        className="absolute w-full shadow-lg rounded-b-lg overflow-hidden"
        {...getMenuProps()}
      >
        {isOpen &&
          itemsTmp &&
          itemsTmp.slice(0, 10).map((item, index) => (
            <li
              className={
                highlightedIndex === index ? "bg-blue-100" : "bg-white"
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {renderItem(item)}
            </li>
          ))}
      </ul>
    </div>
  );
};

AutoComplete.Item = Item;
