import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { API_HOST } from "@constants";

const client = new ApolloClient({
  link: createHttpLink({
    uri: API_HOST,
  }),
  cache: new InMemoryCache(),
});

export default client;
