import React, { FC } from "react";
import { Trans } from "@lingui/macro";

const Icon = () => {
  const strokeWidth = 1.4;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-8 h-8"
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  );
};

export const Item: FC = ({ children }) => {
  return (
    <div className="hover:bg-gray-50 cursor-pointer flex items-center border-b border-gray-100 hover:border-transparent p-4">
      <div>
        <div className="border rounded-full p-2 mr-4 bg-white">
          <Icon />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
