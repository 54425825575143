import React, { FC, HTMLProps, Ref } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Trans } from "@lingui/macro";

type Props = HTMLProps<HTMLInputElement>;

export const Input: FC<Props> = React.forwardRef(
  ({ className = "", ...props }, ref: Ref<HTMLInputElement>) => {
    const classes = classNames(
      "shadow-sm w-full flex items-center rounded-lg bg-white",
      {
        "p-4":
          !className.includes("p-") &&
          !className.includes("px-") &&
          !className.includes("py-"),
      }
    );

    return (
      <div className={twMerge(classes, className)}>
        <svg
          className="w-6 h-6 mr-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <title id="GWGLogoTitle">
            <Trans id="common.svg.title">GoWithGuide</Trans>
          </title>
          <desc id="GWGLogoDesc">
            <Trans id="common.svg.description">
              Find your perfect tour guide at GoWithGuide. Relax and cherish
              unforgettable tours with loved ones. Create personalized tours for
              a truly memorable trip!
            </Trans>
          </desc>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          type="text"
          className="w-full outline-none text-lg bg-transparent"
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
