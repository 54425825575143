import React, { FC } from "react";
import Link from "next/link";
import { t } from "@lingui/macro";
import { CityCardFragment, CountryCardFragment } from "@graphql/types";
import { Image } from "@components/ui/Image";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";

const LocationCard: FC<CountryCardFragment | CityCardFragment> = (location) => {
  const alt = `${location.name} Tour Guide`;

  return (
    <Link href={getAbsoluteUrl(location.url)}>
      <a
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
      >
        <div className="mb-4 overflow-hidden">
          <div className="relative shadow rounded-md overflow-hidden h-24 sm:h-24 bg-gray-300">
            <Image
              src={location.picture?.url || ""}
              className="w-full object-cover h-40 sm:h-32"
              layout="fill"
              alt={alt}
              activeCache={true}
            />
          </div>
          <h3 className="text-center py-2 font-medium truncate">
            {location.name}
          </h3>
        </div>
      </a>
    </Link>
  );
};

export default LocationCard;
